@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.footer {
  width: 100%;
  background-color: #333333;
  min-height: 23vh;
  color: #fff;
  font-family: "Roboto", sans-serif;
}
.footer .footer-inner {
  margin: 0 auto;
  min-height: 23vh;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  font-size: 20px;
  text-align: center;
}
.footer .footer-inner a {
  cursor: pointer;
  color: #fff;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 0 30px;
  }
  .footer .footer-inner {
    text-align: center;
    font-size: 16px;
  }
}/*# sourceMappingURL=footer.css.map */