@import '../Common/common.scss';

$slider-length: 4;
$radioBtn-size: 16px;
$btn-color: #ccc;
$active-color: #333;

.slider {
	min-height: 75vh;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-end;
	justify-content: center;
	
	&__dot {
		width: $radioBtn-size;
		height: $radioBtn-size;
		margin: 2rem $radioBtn-size;
		border-radius: 50%;
		z-index: 10;
		background-color: $btn-color;
		outline: $radioBtn-size / 2 solid $btn-color;
		outline-offset: $radioBtn-size / -2;
		box-shadow: 
			0 0 0 0 $active-color, 
			0 0 0 0 rgba($active-color,0);
		cursor: pointer;
		appearance: none;
		backface-visibility: hidden;

		&:checked {
			background-color: $active-color;
			animation: check 0.5s linear forwards;

			@for $i from 0 to $slider-length {
				&:nth-of-type(#{$i+1}) {
				~ .slider__inner {
					transform: translateX((-100% * $i) / $slider-length);
					}
				}
			}
		}
	}
	
	&__inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% * $slider-length;
		height: 100%;
		transition: all 1s ease-out;
		display: flex;
		flex-flow: row nowrap;
	}
	
	&__body {
		display: flex;
		flex: 1;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		padding: 2rem;
		text-align: center;
	}
	
	&__image {
		font-size: 2.7rem;
		margin-bottom: 2rem;
	}

	&__caption {
		font-weight: bold;
		margin-bottom: 1rem;
		text-transform: uppercase;
	}

	&__text {
		color: #999;
		margin-bottom: 3rem;
		max-width: 300px;
	}
}

.roundImage{
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin-bottom: 20px;
}

h2{
    font-family: $roboto;
    font-size: 20px;
}

h1{
    font-family: $roboto;
    font-size: 40px;
    font-weight: 800;
}

h5{
    margin-bottom: 20px;
    max-width: 500px;
    font-size: 16px;
    color: $lightGray;
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
}


// animation ---------------------------

@keyframes check {
	50% {
		outline-color: $active-color;
		box-shadow: 
			0 0 0 $radioBtn-size $active-color, 
			0 0 0 $radioBtn-size*3 rgba($active-color,0.2);
	}
	100% {
		outline-color: $active-color;
		box-shadow: 
			0 0 0 0 $active-color, 
			0 0 0 0 rgba($active-color,0);
	}
}
