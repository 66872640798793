@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.features {
  width: 100%;
  min-height: 75vh;
  background-color: #333129;
}
.features .features-inner {
  max-width: 1100px;
  min-height: 75vh;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.features .features-inner .left {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -150px;
  background-image: url("../../images/pexels-photo-3584758.jpeg");
}
.features .features-inner .right {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 30px;
  background-color: #fff;
}
.features .features-inner .right .box {
  padding: 10px 0;
  font-family: "Roboto", sans-serif;
}
.features .features-inner .right .box img {
  width: 40px;
  margin-bottom: 20px;
}
.features .features-inner .right .box h2 {
  color: #333129;
  margin-bottom: 20px;
}
.features .features-inner .right .box h5 {
  color: gray;
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 992px) {
  .features {
    padding: 0 30px;
  }
  .features .features-inner .left {
    height: 100%;
    background-position-y: 0;
    background-color: rebeccapurple;
  }
}
@media screen and (max-width: 768px) {
  .features {
    padding: 0 80px 40px 80px;
  }
  .features .features-inner {
    grid-template-columns: 1fr;
  }
  .features .features-inner .left {
    height: 800px;
  }
}
@media screen and (max-width: 576px) {
  .features {
    padding: 0 30px 40px 30px;
  }
  .features .features-inner .left {
    height: 500px;
  }
}/*# sourceMappingURL=features.css.map */