@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.headerDownSection {
  width: 100%;
  min-height: 50vh;
}
.headerDownSection .headerDownSection-inner {
  min-height: 50vh;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  padding: 30px 0;
}
.headerDownSection .headerDownSection-inner .up {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.headerDownSection .headerDownSection-inner .up .right {
  padding: 30px;
  display: flex;
  align-items: center;
  text-align: justify;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: normal;
}
.headerDownSection .headerDownSection-inner .up .right h5 {
  font-weight: 400;
}
.headerDownSection .headerDownSection-inner .down {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 40px 20px;
}
.headerDownSection .headerDownSection-inner .down .left {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.headerDownSection .headerDownSection-inner .down .left h1 {
  font-weight: 600;
  font-size: 70px;
  font-family: Arial, Helvetica, sans-serif;
}
.headerDownSection .headerDownSection-inner .down .left h2 {
  letter-spacing: 7px;
  font-family: "Roboto", sans-serif;
  font-weight: lighter;
}
.headerDownSection .headerDownSection-inner .down .right {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.headerDownSection .headerDownSection-inner .down .right .left-inner {
  padding: 20px;
  font-family: "Roboto", sans-serif;
}
.headerDownSection .headerDownSection-inner .down .right .left-inner h1 {
  font-size: 50px;
  margin-bottom: 20px;
}
.headerDownSection .headerDownSection-inner .down .right .left-inner h5 {
  font-family: Arial, Helvetica, sans-serif;
}
.headerDownSection .headerDownSection-inner .down .right .left-inner h3 {
  margin-bottom: 10px;
}
.headerDownSection .headerDownSection-inner .down .right .right-inner {
  padding: 20px;
  font-family: "Roboto", sans-serif;
}
.headerDownSection .headerDownSection-inner .down .right .right-inner h1 {
  font-size: 50px;
  margin-bottom: 20px;
}
.headerDownSection .headerDownSection-inner .down .right .right-inner h5 {
  font-family: Arial, Helvetica, sans-serif;
}
.headerDownSection .headerDownSection-inner .down .right .right-inner h3 {
  margin-bottom: 10px;
}

@media screen and (max-width: 992px) {
  .headerDownSection .headerDownSection-inner {
    padding: 10px;
  }
  .headerDownSection .headerDownSection-inner .up {
    grid-template-columns: 1fr;
  }
  .headerDownSection .headerDownSection-inner .down {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 768px) {
  .headerDownSection .headerDownSection-inner .down .left h1 {
    font-size: 50px;
  }
  .headerDownSection .headerDownSection-inner .down .right {
    grid-template-columns: 1fr;
  }
}/*# sourceMappingURL=headerDownSection.css.map */