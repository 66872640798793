@import '../Common/common.scss';

.active{
    color: $black !important;
}

.navbar{
    width: 100%;
    min-height: 80px;
    border: 1px solid $gray;
    
    .navbar-inner{
        max-width: 1200px;
        margin: 0 auto;
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo{

        }

        .nav-links{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 50px;

            .close{
                cursor: pointer;
                display: none;
            }   

            .link{

                h5{
                    font-size: 20px;
                     color: $gray;
                     font-family: $roboto; 

                    &:hover{
                        color: $darkgray;
                    }
                }
            }
        }

        .menu{
            cursor: pointer;
            display: none;
        }
    }
}

@media screen and (max-width: 1250px) {
    .navbar{
        
        .navbar-inner{
            padding: 30px;
            
            .logo{
    
            }
    
            .nav-links{
    
                .link{
    
                    h5{
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .navbar{
        
        .navbar-inner{
            
            .logo{
    
            }
    
            .nav-links{
                position: absolute;
                top: 0;
                left: 0;
                background-color: $darkgray;
                min-height: 100vh;
                width: 75%;
                flex-direction: column;
                justify-content: flex-start;
                padding-top: 100px;
                transform: translateX(-100%);

                .close{
                    display: block;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    color: $lightGray;
                }
    
                .link{
                    flex-direction: column;
                    top: 100px;
    
                    h5{
                        font-size: 16px; 
                        top: 100px;

                        &:hover{
                            color: $lightGray;
                        }
                    }
                }
            }

            .menu{
                display: block;
                color: $gray;
            }
        }
    }
}