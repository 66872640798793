@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.slider {
  min-height: 75vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: center;
}
.slider__dot {
  width: 16px;
  height: 16px;
  margin: 2rem 16px;
  border-radius: 50%;
  z-index: 10;
  background-color: #ccc;
  outline: 8px solid #ccc;
  outline-offset: -8px;
  box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.slider__dot:checked {
  background-color: #333;
  -webkit-animation: check 0.5s linear forwards;
          animation: check 0.5s linear forwards;
}
.slider__dot:checked:nth-of-type(1) ~ .slider__inner {
  transform: translateX(0%);
}
.slider__dot:checked:nth-of-type(2) ~ .slider__inner {
  transform: translateX(-25%);
}
.slider__dot:checked:nth-of-type(3) ~ .slider__inner {
  transform: translateX(-50%);
}
.slider__dot:checked:nth-of-type(4) ~ .slider__inner {
  transform: translateX(-75%);
}
.slider__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 400%;
  height: 100%;
  transition: all 1s ease-out;
  display: flex;
  flex-flow: row nowrap;
}
.slider__body {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
}
.slider__image {
  font-size: 2.7rem;
  margin-bottom: 2rem;
}
.slider__caption {
  font-weight: bold;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.slider__text {
  color: #999;
  margin-bottom: 3rem;
  max-width: 300px;
}

.roundImage {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  margin-bottom: 20px;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 800;
}

h5 {
  margin-bottom: 20px;
  max-width: 500px;
  font-size: 16px;
  color: #B3B3B3;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
}

@-webkit-keyframes check {
  50% {
    outline-color: #333;
    box-shadow: 0 0 0 16px #333, 0 0 0 48px rgba(51, 51, 51, 0.2);
  }
  100% {
    outline-color: #333;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  }
}

@keyframes check {
  50% {
    outline-color: #333;
    box-shadow: 0 0 0 16px #333, 0 0 0 48px rgba(51, 51, 51, 0.2);
  }
  100% {
    outline-color: #333;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  }
}/*# sourceMappingURL=slider.css.map */