@import '../Common/common.scss';

.headerDownSection{
    width: 100%;
    min-height: 50vh;

    .headerDownSection-inner{
        min-height: 50vh;
        max-width: 1200px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr;
        padding: 30px 0;

        .up{
            display: grid;
            grid-template-columns: 1fr 1fr;

            .left{
            }

            .right{
                padding: 30px;
                display: flex;
                align-items: center;
                text-align: justify;
                font-family: $roboto;
                font-size: 20px;
                font-weight: normal;

                h5{
                    font-weight: 400;
                }
            }
        }

        .down{
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 40px 20px;


            .left{
                display: flex;
                justify-content: center;
                flex-direction: column;

                h1{
                    font-weight: 600;
                    font-size: 70px;
                    font-family: Arial, Helvetica, sans-serif;
                }

                h2{
                    letter-spacing: 7px;
                    font-family: $roboto;
                    font-weight: lighter;
                }
            }

            .right{
                display: grid;
                grid-template-columns: 1fr 1fr;

                .left-inner{
                    padding: 20px;            
                    font-family: $roboto;

                    h1{
                        font-size: 50px;
                        margin-bottom: 20px;
                    }

                    h5{
                        font-family: Arial, Helvetica, sans-serif;
                    }

                    h3{
                        margin-bottom: 10px;
                    }
                }

                .right-inner{
                    padding: 20px;
                    font-family: $roboto;

                    h1{
                        font-size: 50px;
                        margin-bottom: 20px;
                    }

                    h5{
                        font-family: Arial, Helvetica, sans-serif;
                    }

                    h3{
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .headerDownSection{
    
        .headerDownSection-inner{
            padding: 10px;
    
            .up{
                grid-template-columns: 1fr;
    
                .left{
                }
    
                .right{
                  
                    h5{

                    }
                }
            }
    
            .down{
                grid-template-columns: 1fr;
    
                .left{
    
                    h1{

                    }
    
                    h2{
                       
                    }
                }
    
                .right{
    
                    .left-inner{
                       
    
                        h1{
                          
                        }
    
                        h5{
    
                        }
    
                        h3{
                        }
                    }
    
                    .right-inner{
    
                        h1{
                        }
    
                        h5{
    
                        }
    
                        h3{
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .headerDownSection{
    
        .headerDownSection-inner{
    
            .up{
    
                .left{
                }
    
                .right{
                  
                    h5{

                    }
                }
            }
    
            .down{
    
                .left{
    
                    h1{
                        font-size: 50px;
                    }
    
                    h2{
                       
                    }
                }
    
                .right{
                    grid-template-columns: 1fr;
    
                    .left-inner{
    
                        h1{
                          
                        }
    
                        h5{
    
                        }
    
                        h3{
                        }
                    }
    
                    .right-inner{
    
                        h1{
                        }
    
                        h5{
    
                        }
    
                        h3{
                        }
                    }
                }
            }
        }
    }
}