@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.underFeatures {
  width: 100%;
  min-height: 75vh;
  background-image: url("../../images/pexels-photo-1391404.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.underFeatures .underFeatures-inner {
  max-width: 1100px;
  min-height: 75vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.underFeatures .underFeatures-inner .wrapper {
  max-width: 370px;
  height: 100%;
  min-height: 75vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}
.underFeatures .underFeatures-inner .wrapper .box {
  background-color: #fff;
  padding: 20px;
}
.underFeatures .underFeatures-inner .wrapper .box:nth-child(2) {
  background-color: #000;
}
.underFeatures .underFeatures-inner .wrapper .box:nth-child(2) h2 {
  color: #fff;
}
.underFeatures .underFeatures-inner .wrapper .box img {
  width: 40px;
  margin-bottom: 20px;
}
.underFeatures .underFeatures-inner .wrapper .box h2 {
  color: #333129;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.underFeatures .underFeatures-inner .wrapper .box h5 {
  color: gray;
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 992px) {
  .underFeatures .underFeatures-inner {
    padding: 0 30px;
  }
  .underFeatures .underFeatures-inner .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 768px) {
  .underFeatures .underFeatures-inner {
    justify-content: center;
    padding: 30px 30px;
  }
  .underFeatures .underFeatures-inner .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}/*# sourceMappingURL=underFeatures.css.map */